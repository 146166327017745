@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-white text-gray-800 dark:bg-gray-900 dark:text-gray-300;
  }
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 35%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.3% 26.1%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --success: 142 70% 45%;
    --success-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
    --link: 240 70% 50%;
    --link-foreground: 240 5.9% 10%;
  }
  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --success: 142 70% 45%;
    --success-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
    --link: 240 3.7% 15.9%;
  }
}
html {
  height: 100vh;
}
@layer components {
  .btn {
    @apply w-fit rounded-sm bg-black px-3 py-2 text-sm font-semibold text-white transition-colors hover:bg-offblack;
  }
  .sm-icon {
    @apply h-5 w-5  stroke-current;
  }
  .discreet-btn {
    @apply bg-transparent text-gray-500 hover:bg-vlight hover:text-offblack;
  }
  .input-field {
    @apply rounded-xl border border-divider bg-offwhite  px-4 py-2 text-sm shadow-sm transition-all placeholder:text-muted focus:outline-none  dark:bg-gray-800 dark:text-gray-300;
  }
  .input-field-sm {
    @apply w-full rounded-xl bg-white px-4 py-2 text-xl shadow-sm transition-all placeholder:text-gray-600 focus:outline-none dark:bg-gray-900 dark:text-gray-200 dark:placeholder:text-gray-400;
  }
  .tooltip {
    @apply bg-white p-1 rounded-md shadow-md text-sm text-gray-800 absolute;
  }
  .leaflet-container {
    @apply z-[10] !important; /* Adjust the z-index value as needed */
  }
}

@layer utilities {
  .bg-custom-svg {
    background-image: url("/check.svg");
    @apply bg-[length:1.5rem_1.5rem] bg-left-top bg-no-repeat pl-8;
  }

  .clip-your-needful-style {
    clip-path: polygon(50% 0%, 50% 50%, 87% 0%);
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}
.overflow-x-scroll {
  scrollbar-width: auto;
}

::-webkit-scrollbar {
  background-color: transparent;
  padding: 0;
  width: 1px;
  height: 1px;
}
.overflow-x-scroll::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
::-webkit-scrollbar-thumb:active {
  background-color: rgba(155, 155, 155, 0.5);
}
::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 2px;
}
@layer base {
  * {
    @apply border-border outline-ring/50;
  }
  body {
    @apply bg-background text-foreground;
  }
}
